/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { AppProvider } from './src/context/AppContext';

/* eslint-disable  react/jsx-filename-extension */
export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
