export const PAGE_ROUTES = {
  HomePage: '/',
  Jobs: '/careers',
  Blogs: '/blogs',
};
export const VALUES_COMPANY = {
  OUR_CULTURE: 'Our Culture',
  BENEFITS: 'Perks & Benefits',
  OUR_STORY: 'Our Story',
};
export const HAS_ACCESS_TO = 'hasAccessTo';
export const isDesktopScreen = typeof window !== 'undefined' ? window.innerWidth >= 1025 : false;

export const isFullHeightScreen = typeof window !== 'undefined' ? screen.height - window.innerHeight < 150 : false; //Use to detect fullpage

export const isFullPage = isDesktopScreen && isFullHeightScreen;