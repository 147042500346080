import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Cursor from '../components/Layout/Cursor';
// import { isMobile } from 'react-device-detect';
import { getWindow } from '../utils/app';

const defaultState = {
  mouseY: 0,
  setMouseY: () => {},
  mouseX: 0,
  setMouseX: () => {},
  showMouse: false,
  setShowMouse: () => {},
  isOpenConfirmModal: false,
  setOpenConfirmModal: () => {},
};

export const AppContext = createContext(defaultState);
export const AppProvider = ({ children }) => {
  const [mouseY, setMouseY] = useState(0);
  const [mouseX, setMouseX] = useState(0);
  const [showMouse, setShowMouse] = useState(false);
  const [theme, setTheme] = useState('light');
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    const preferDarkQuery = '(prefers-color-scheme: dark)';
    const mediaQueryList = getWindow().matchMedia(preferDarkQuery);
    const reloadPage = () => {
      getWindow().location.reload();
    };

    mediaQueryList.onchange = reloadPage;
  }, []);

  return (
    <AppContext.Provider
      value={{
        mouseY,
        setMouseY,
        mouseX,
        setMouseX,
        showMouse,
        setShowMouse,
        theme,
        setTheme,
        isOpenConfirmModal,
        setOpenConfirmModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.any,
};
