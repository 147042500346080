import { navigate } from 'gatsby';
import TweenMax from 'gsap';
import { PAGE_ROUTES } from './constants';

export const getWindow = () => (typeof window !== 'undefined' ? window : {});
export const handleNextPageLink = currentPath => {
  switch (currentPath) {
    case PAGE_ROUTES.HomePage:
      return navigate(PAGE_ROUTES.Jobs);
    case PAGE_ROUTES.Jobs:
      return navigate(PAGE_ROUTES.HomePage);
    default:
      return navigate(PAGE_ROUTES.HomePage);
  }
};
export const checkServer = () => {
  return typeof window === 'undefined';
};
export const handleHoverCursor = isAddEventListener => {
  const bigBall = document.querySelector('.cursor__ball--big');
  const hoverables = document.querySelectorAll('.hoverable');

  if (!hoverables && hoverables.length < 1) return;

  // Hover an element
  function onMouseHover() {
    TweenMax.to(bigBall, 0.3, {
      scale: 2,
    });
  }

  function onMouseHoverOut() {
    TweenMax.to(bigBall, 0.3, {
      scale: 1,
    });
  }

  if (isAddEventListener) {
    for (let i = 0; i < hoverables.length; i++) {
      hoverables[i].addEventListener('mouseenter', onMouseHover);
      hoverables[i].addEventListener('mouseleave', onMouseHoverOut);
    }
  } else {
    for (let i = 0; i < hoverables.length; i++) {
      hoverables[i].removeEventListener('mouseenter', onMouseHover);
      hoverables[i].removeEventListener('mouseleave', onMouseHoverOut);
    }
  }
};
export const isDarkMode = !checkServer()
  ? getWindow().matchMedia('(prefers-color-scheme: dark)').matches
  : false;
export const isLightMode = !checkServer()
  ? getWindow().matchMedia('(prefers-color-scheme: light)').matches
  : false;
export const isNotSpecified = !checkServer()
  ? getWindow().matchMedia('(prefers-color-scheme: no-preference)').matches
  : true;
